@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Scrollbar Utilties */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@layer base {
  @font-face {
    font-family: "Sohne";
    src: url("./assets/fonts/Sohne/soehne-buch.woff2") format("woff2");
    font-weight: 400; /* font-normal */
    font-style: normal;
  }
  @font-face {
    font-family: "Sohne";
    src: url("./assets/fonts/Sohne/soehne-kraftig.woff2") format("woff2");
    font-weight: 500; /* font-medium */
    font-style: normal;
  }
  @font-face {
    font-family: "Sohne";
    src: url("./assets/fonts/Sohne/soehne-halbfett.woff2") format("woff2");
    font-weight: 600; /* font-semibold */
    font-style: normal;
  }

  .font-inter {
    font-family: "Sohne", sans-serif; /* Specify the Sohne font family */
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
  }

  span {
    width: fit-content;
  }

  button {
    outline: none;
    @apply transition duration-200 ease-in-out;
  }

  a {
    @apply transition duration-200 ease-in-out;
  }

  input,
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea,
  select {
    @apply leading-normal;
  }

  #root {
    @apply text-primary surface-primary flex min-h-screen flex-col font-inter leading-normal tracking-normal;
  }

  body {
    @apply text-sm;
  }

  /* Text Styles */
  .text-primary {
    @apply text-gray-900 dark:text-gray-100;
  }

  .text-secondary {
    @apply text-gray-800 dark:text-gray-200;
  }

  .text-sub {
    @apply text-gray-700 dark:text-gray-300;
  }

  .text-icons {
    @apply text-gray-400 dark:text-gray-400;
  }

  .text-brand {
    @apply text-brand-blue dark:text-brand-blue;
  }

  .text-white {
    @apply text-brand-white dark:text-brand-black;
  }

  .text-black {
    @apply text-brand-black dark:text-brand-white;
  }

  .text-gray {
    @apply text-gray-600 dark:text-gray-400;
  }

  .text-link {
    @apply text-brand-lightblue dark:text-brand-lightblue;
  }

  /* Surface Styles */

  .surface-primary {
    @apply bg-white dark:bg-dark-1;
  }

  .surface-secondary {
    @apply bg-gray-100 dark:bg-dark-3;
  }

  .surface-sub {
    @apply bg-gray-700 dark:bg-dark-4;
  }

  .surface-brand {
    @apply bg-brand-blue dark:bg-brand-blue;
  }

  .surface-white {
    @apply bg-white dark:bg-dark-2;
  }

  .surface-nav {
    @apply bg-white dark:bg-dark-1;
  }

  .surface-header {
    @apply bg-white dark:bg-dark-2;
  }

  .surface-50 {
    @apply bg-gray-50 dark:bg-dark-2;
  }

  .surface-db-stepper {
    @apply bg-gray-50 dark:bg-black;
  }

  .surface-border-primary {
    @apply bg-gray-200 dark:bg-dark-3;
  }

  /* Labels Styles */

  .label-partner-bg {
    @apply bg-orange-100 dark:bg-orange-700;
  }

  .label-partner-text {
    @apply text-orange-700 dark:text-orange-100;
  }

  .label-user-bg {
    @apply bg-green-100 dark:bg-green-700;
  }

  .label-user-text {
    @apply text-green-700 dark:text-green-100;
  }

  .label-customer-bg {
    @apply bg-blue-50 dark:bg-blue-500;
  }

  .label-customer-text {
    @apply text-blue-500 dark:text-blue-50;
  }

  .label-admin-bg {
    @apply bg-gray-50 dark:bg-gray-500;
  }

  .label-admin-text {
    @apply text-gray-500 dark:text-gray-50;
  }

  .label-red-bg {
    @apply bg-red-50 dark:bg-red-600;
  }

  .label-red-text {
    @apply text-red-600 dark:text-red-50;
  }

  .label-completed-bg {
    @apply bg-green-100 dark:bg-green-500;
  }

  .label-completed-text {
    @apply text-green-400 dark:text-green-50;
  }

  .label-progress-bg {
    @apply bg-orange-50 dark:bg-orange-500;
  }

  .label-progress-text {
    @apply text-orange-500 dark:text-orange-50;
  }

  .label-assess-text {
    @apply text-blue-500 dark:text-blue-50;
  }

  .label-notstarted-bg {
    @apply bg-gray-200 dark:bg-gray-600;
  }

  .label-notstarted-text {
    @apply text-gray-600 dark:text-gray-200;
  }
}

@layer components {
  /* BUTTON */
  .btn {
    @apply relative flex items-center justify-center rounded-lg transition duration-200 ease-in-out enabled:hover:shadow-sm disabled:cursor-not-allowed disabled:opacity-50;
  }

  .btn-brand {
    @apply btn bg-blue-50 text-surface-brand focus:ring-4 focus:ring-blue-300 enabled:hover:bg-blue-200;
  }

  .btn-white {
    @apply btn text-primary border-primary w-full gap-2 border bg-white px-3 py-2 hover:bg-gray-100 disabled:opacity-50 laptop:w-fit;
  }

  .btn-green {
    @apply btn bg-green-50 text-green-500 enabled:hover:bg-green-200;
  }

  .btn-logout {
    @apply btn bg-transparent text-dark-brand enabled:hover:underline enabled:hover:opacity-70;
  }

  .btn-disabled {
    @apply btn text-gray bg-gray-200 enabled:hover:bg-gray-300;
  }

  /* LINK */
  .link {
    @apply text-link outline-none transition-all duration-200 ease-in-out hover:opacity-70 focus:underline;
  }

  .btn-text {
    @apply text-brand-blue dark:text-blue-100;
  }

  .btn-text-red {
    @apply text-[#DC2626];
  }

  .btn-text-green {
    @apply text-[#15803D];
  }

  .btn-bg {
    @apply bg-blue-50 dark:bg-brand-blue;
  }

  .btn-bg-red {
    @apply bg-[#FEF2F2];
  }

  .btn-pulse {
    /* background: rgb(211, 228, 246); */
    box-shadow: 0 0 0 6px rgb(225, 239, 254, 1);
    animation: pulse-next 2s infinite;
  }

  .btn-pulse:hover {
    box-shadow: none;
    animation: none;
  }

  @keyframes pulse-next {
    0% {
      box-shadow: 0 0 0 0 rgba(74, 163, 252, 0.8);
    }

    70% {
      box-shadow: 0 0 0 8px rgba(51, 217, 178, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
  }

  .btn-pulse-purple {
    box-shadow: 0 0 0 6px rgb(225, 239, 254, 1);
    animation: pulse-next-purple 2s infinite;
  }

  .btn-pulse-purple:hover {
    box-shadow: none;
    animation: none;
  }

  @keyframes pulse-next-purple {
    0% {
      box-shadow: 0 0 0 0 rgba(172, 148, 250, 0.8);
    }

    70% {
      box-shadow: 0 0 0 8px rgba(51, 217, 178, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
  }

  .btn-pulse-sky {
    box-shadow: 0 0 0 6px rgb(225, 239, 254, 1);
    @apply animate-pulse-sky;
  }

  .btn-pulse-sky:hover {
    @apply animate-none shadow-none;
  }

  /* Border Styles */

  .border-primary {
    @apply border-gray-200 dark:border-dark-3;
  }

  .border-secondary {
    @apply border-gray-300 dark:border-dark-4;
  }

  /** Outline Styles */
  .outline-primary {
    @apply outline-gray-200 dark:outline-dark-3;
  }

  .outline-secondary {
    @apply outline-gray-300 dark:outline-dark-4;
  }

  /* Nav Styles */
  .nav-icons {
    @apply size-5;
  }

  .nav-icons-closed {
    @apply h-6 w-6;
  }

  .navDefault {
    @apply my-2 flex w-full items-center rounded-lg px-4 py-1 transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700;
  }

  .nav-active {
    @apply flex w-full text-base font-bold;
  }

  .nav-pending {
    @apply hover:text-secondary flex w-full text-base font-medium;
  }

  /* Breadcrumbs Styles */

  .breadcrumbs-active {
    @apply text-brand-white;
  }

  .breadcrumbs-layout {
    @apply flex flex-row items-center justify-center gap-3 text-sm font-medium;
  }

  /* Auth Styles */

  .auth-layout-bg {
    @apply bg-[url('assets/images/login-logo.png')] bg-right-bottom bg-no-repeat dark:bg-[url('assets/images/login-logo-dark.png')];
  }

  /* Forms Styles */

  /* .search-input {
    @apply surface-primary border-secondary text-gray block w-full rounded-lg p-4 ps-10 text-sm focus:border-blue-500 focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:ring-blue-500;
  } */

  /* Common styles */

  .form-container {
    @apply flex w-full flex-col justify-between gap-8 rounded-lg border border-gray-200 bg-white p-8 drop-shadow-md 
    tablet:w-[950px] tablet:flex-row tablet:gap-16
    desktop:w-[1024px];
    /* @apply w-full rounded-lg border border-gray-200 bg-gradient-to-tl from-white via-white/70 to-white/70 p-8 drop-shadow-2xl backdrop-blur-sm tablet:w-[500px];
    background: radial-gradient(
      218.08% 100% at 50% 0%,
      #fff 0%,
      rgba(255, 255, 255, 0.9) 100%,
      rgba(255, 255, 255, 0.9) 100%
    ); */
  }

  .panel-common {
    @apply border-primary surface-white flex flex-col rounded-lg border p-5 shadow-md;
  }

  .w-responsive {
    @apply flex w-full flex-col justify-between gap-8 rounded-lg border border-gray-200 bg-white p-8 drop-shadow-md 
    tablet:w-[760px] tablet:flex-row tablet:gap-16
    desktop:w-[1000px];
  }
}

@layer utilities {
  /* iframe#webpack-dev-server-client-overlay {
    display: none;
  } */

  .dropdown-transition {
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
  }
}
